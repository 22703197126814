import SalesCSS from "../styles/sales-calc";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import Axios from "axios";
import Sortable from "sortablejs";

window.Axios = Axios;
window.Sortable = Sortable;

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

import Rails from "rails-ujs";
import "trix";
import "actiontext";
Rails.start();

import { App as ExportApp } from "../export/app";
window.ExportApp = ExportApp;
