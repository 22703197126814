import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bpForm", "fteForm", "tcpForm"];

  connect() {
    const active = this.data.get("active");

    switch (active) {
      case "basis_point":
        this.switch_basis_point();
        break;
      case "full_time_employees":
        this.switch_full_time_employees();
        break;
      case "total_contract_percentage":
        this.switch_total_contract_percentage();
        break;
    }
  }

  switch_basis_point() {
    this.bpFormTarget.classList.remove("hidden");
    this.fteFormTarget.classList.add("hidden");
    this.tcpFormTarget.classList.add("hidden");
  }

  switch_full_time_employees() {
    this.bpFormTarget.classList.add("hidden");
    this.fteFormTarget.classList.remove("hidden");
    this.tcpFormTarget.classList.add("hidden");
  }

  switch_total_contract_percentage() {
    this.bpFormTarget.classList.add("hidden");
    this.fteFormTarget.classList.add("hidden");
    this.tcpFormTarget.classList.remove("hidden");
  }
}
