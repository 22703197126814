import * as React from "react";
import { render } from "react-dom";
import { Provider, observer } from "mobx-react";

import RootStore, { Columns, Option } from "./stores/root";
const cable = require("actioncable");

const {
  Box,
  Button,
  Checkbox,
  Flex,
  Font,
  Popover,
} = require("@procore/core-react");

interface Props {
  company_name: string;
  edit_path: string;
  starter_image_path: string;
  default_image_path: string;
  owner_image_path: string;
  excluded_image_path: string;
  slick: "starter" | "default" | "owner" | "excluded";
  logo_path: string;
  options: Option[];
  quote_id: number;
  sell_multiple: boolean;
}

@observer
export class App extends React.Component<Props, {}> {
  store: RootStore;

  constructor(props: Props) {
    super(props);
    this.store = new RootStore({
      options: props.options,
      edit_path: props.edit_path,
      sell_multiple: props.sell_multiple,
    });
  }

  componentDidMount() {
    const store = this.store;
    const consumer = cable.createConsumer();
    consumer.subscriptions.create(
      { channel: "QuoteChannel", id: this.props.quote_id },
      {
        received(data: any) {
          switch (data.action) {
            case "updated":
              store.refresh(data.payload);
              break;
          }
        },
      },
    );
  }

  public static render(element: HTMLElement, props: Props) {
    console.log(props)
    render(React.createElement(this, props), element);
  }

  _pdf_link = () => {
    const configuredColumns = [];
    if (this.store.toggled(Columns.Quantities)) {
      configuredColumns.push("quantities");
    }

    if (this.store.toggled(Columns.BasisPoints)) {
      configuredColumns.push("basis_points");
    }

    if (this.store.toggled(Columns.PerMillion)) {
      configuredColumns.push("price_per_million");
    }

    if (this.store.toggled(Columns.PerProject)) {
      configuredColumns.push("price_per_project_per_month");
    }

    if (this.store.toggled(Columns.PerEmployee)) {
      configuredColumns.push("price_per_employee_per_month");
    }

    return `${window.location.href}.pdf?columns[]=${configuredColumns.join(
      "&columns[]=",
    )}&tax_disclaimer=${this.store.disclaimers.taxes}&page_break=${this.store.pdfoptions.page_break}&prof_services=${this.store.subtotals.prof_services}&descriptions=${this.store.descriptions.enabled}`;
  };

  render() {
    return (
      <Provider store={this.store}>
        <div className="export">
          <header className="export__header">
            <a href="/reps/companies">
              <img src={this.props.logo_path} height={20} />
            </a>

            <Flex className="no-print">
              <ColumnDropdown store={this.store} />
              <Box padding="none sm">
                <Button variant="secondary">
                  <a target="_blank" href={this._pdf_link()}>
                    PDF
                  </a>
                </Button>
              </Box>
              <Box padding="none">
                <Button variant="secondary">
                  <a href={this.props.edit_path}>Edit</a>
                </Button>
              </Box>
            </Flex>
          </header>
          <section className="export__body">
            <div className="export__details">
              <h1> {this.props.company_name.replace(/&amp;/g, "&").replace(/&#39;/g, "'").replace(/&quot;/g, "\"")} </h1>
              <h2 style={{ marginBottom: "2%" }}> Pricing Options </h2>
              {this.store.visible.map(option => (
                <Option key={option.name} store={this.store} option={option} />
              ))}
            </div>
            <div className="export__product-lines no-print">
              {(() => {
                switch (this.props.slick) {
                  case "default":
                    return (
                      <a href={this.props.default_image_path} target="_blank">
                        {<img src={this.props.default_image_path} />}
                      </a>
                    );

                  case "starter":
                    return (
                      <a href={this.props.starter_image_path} target="_blank">
                        {<img src={this.props.starter_image_path} />}
                      </a>
                    );

                  case "owner":
                    return (
                      <a href={this.props.owner_image_path} target="_blank">
                        {<img src={this.props.owner_image_path} />}
                      </a>
                    );

                  case "excluded":
                    if (this.props.excluded_image_path != "") {
                      return (
                        <a href={this.props.excluded_image_path} target="_blank">
                          {<img src={this.props.excluded_image_path} />}
                        </a>
                      );
                    }
                }
              })()}
            </div>
          </section>
        </div>
      </Provider>
    );
  }
}

const Option = observer(
  ({ store, option }: { store: RootStore; option: Option }) => (
    <div className="export__card" style={{marginBottom: "5%"}}>
      <h2 className="export__card__header">{option.name}</h2>
      {option.calculation.years.map(year => (
        <Box paddingTop="lg" key={year.index}>
          <h3 style={{paddingTop: "1%"}}>Year: {year.index + 1}</h3>
          <Font size="xs" weight="regular" variant="secondary">
            ACV: {year.acv} million
            {store.toggled(Columns.PerProject) && ( ` | Projects: ${option.calculation.project_count}`)}
            {store.toggled(Columns.PerEmployee) && ( ` | Employees: ${option.calculation.employee_count}`)}
          </Font>
          <table className="table table--quote" style={{marginBottom: "3%"}}>
            <thead>
              <tr>
                <th style={{ width: "20%", verticalAlign: "bottom" }}> Name </th>
                {store.toggled(Columns.Quantities) &&
                  <th style={{ width: "12%", verticalAlign: "bottom"}} className="quote__price"> Quantity </th>
                }
                <th
                  style={{ width: "10%", verticalAlign: "bottom" }}
                  className="quote__price"
                >
                  Price <br />
                  {frequencyDescription(option.calculation.frequency)}
                </th>
                {store.toggled(Columns.BasisPoints) && (
                  <th
                    className="quote__price"
                    style={{ width: "12%", verticalAlign: "bottom" }}
                  >
                    Basis Points
                  </th>
                )}
                {store.toggled(Columns.PerMillion) && (
                  <th
                    className="quote__price"
                    style={{ width: "12%", verticalAlign: "bottom" }}
                  >
                    Price Per Million <br />
                    {frequencyDescription(option.calculation.frequency)}
                  </th>
                )}
                {store.toggled(Columns.PerProject) && (
                  <th
                    className="quote__price"
                    style={{ width: "12%", verticalAlign: "bottom" }}
                  >
                    Cost Per Job
                    <br />
                    Per Month
                  </th>
                )}
                {store.toggled(Columns.PerEmployee) && (
                  <th
                    className="quote__price"
                    style={{ width: "12%", verticalAlign: "bottom" }}
                  >
                    Cost Per Employee
                    <br />
                    Per Month
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {year.sellables.map(sellable => (
                <tr key={sellable.id}>
                  <td>{sellable.name}</td>
                  {store.toggled(Columns.Quantities) &&
                    <td className="quote__price">{sellable.quantity}</td>
                  }
                  <td className="quote__price">{sellable.total}</td>
                  {store.toggled(Columns.BasisPoints) && (
                    <td className="quote__price">
                      {sellable.total_bp}
                    </td>
                  )}
                  {store.toggled(Columns.PerMillion) && (
                    <td className="quote__price">
                      {sellable.price_per_million}
                    </td>
                  )}
                  {store.toggled(Columns.PerProject) && (
                    <td className="quote__price">
                      {sellable.price_per_project_per_month}
                    </td>
                  )}
                  {store.toggled(Columns.PerEmployee) && (
                    <td className="quote__price">
                      {sellable.price_per_employee_per_month}
                    </td>
                  )}
                </tr>
              ))}
              <tr className="quote__total">
                <td>
                  <strong>Total </strong>
                  {store.disclaimers.taxes && (
                    <span>
                      <Font size="xs" variant="secondary">
                        (Excludes Taxes)
                      </Font>
                    </span>
                  )}
                </td>
                {store.toggled(Columns.Quantities) &&
                  <td className="quote__price">
                    <strong>{year.qty}</strong>
                  </td>
                }
                <td className="quote__price">
                  <strong>{year.total}</strong>
                </td>
                {store.toggled(Columns.BasisPoints) && (
                  <td className="quote__price">
                    <strong>{year.total_bp}</strong>
                  </td>
                )}
                {store.toggled(Columns.PerMillion) && (
                  <td className="quote__price">
                    <strong>{year.price_per_million}</strong>
                  </td>
                )}
                {store.toggled(Columns.PerProject) && (
                  <td className="quote__price">
                    <strong>{year.price_per_project_per_month}</strong>
                  </td>
                )}
                {store.toggled(Columns.PerEmployee) && (
                  <td className="quote__price">
                    <strong>{year.price_per_employee_per_month}</strong>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          {store.descriptions.enabled && (
              <div className="export__footnotes">
                {year.sellables.filter(sellable => sellable.description).map(sellable => (
                    <div key={sellable.id}><span className="export__description__title">{sellable.name}:</span><span>{sellable.description}</span></div>
                ))}
              </div>
          )}
        </Box>
      ))}

      {((option.calculation.years.length > 1 ||
        option.calculation.frequency > 1) && !store.subtotals.prof_services) &&
        <Box paddingTop="lg">
          Total Price: <strong>{option.calculation.total}</strong>
          {store.disclaimers.taxes && (
            <Box>
              <span>
                <Font size="xs" variant="secondary">
                  Excludes Taxes
                </Font>
              </span>
            </Box>
          )}
        </Box>
      }

      { store.subtotals.prof_services && (
        <Box paddingTop="lg">
          <h3 className="export__card__header" style={{padding: "1% 0"}}> Summary </h3>
            <table className="table table--quote">
              <thead>
                <tr>
                  <th> Name </th>
                  <th className="quote__price"> Total </th>
                </tr>
              </thead>
              <tbody>
              {store.subtotals.prof_services &&
                <tr>
                  <td className=""> Annual Recurring Fees </td>
                  <td className="quote__price"> {option.calculation.arr} </td>
                </tr>
              }
              {store.subtotals.prof_services &&
                <tr className="">
                  <td className=""> One Time Fees </td>
                  <td className="quote__price"> {option.calculation.otc} </td>
                </tr>
              }
                <tr className="quote__total">
                  <td className="">
                    <strong> Total Price </strong>
                    {store.disclaimers.taxes && (
                      <span>
                        <Font size="xs" variant="secondary">
                          (Excludes Taxes)
                        </Font>
                      </span>
                    )}
                  </td>
                  <td className="quote__price">
                    <strong>{option.calculation.total}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
        </Box>
      )}
    </div>
  ),
);

const ColumnDropdown = observer(({ store }: { store: RootStore }) => (
  <Popover
    trigger="click"
    placement="bottom"
    overlay={
      <Popover.Content>
        <Box padding="md">
          <Box padding="sm none">
            <Font weight="bold">Columns</Font>
          </Box>
          <Box padding="none none sm none">
            <Checkbox
              value={Columns.Quantities}
              checked={store.toggled(Columns.Quantities)}
              onChange={(e: any) => {
                store.toggleColumn(Columns.Quantities);
              }}
            >
              {Columns.Quantities}
            </Checkbox>
          </Box>

          <Box padding="sm none">
            <Checkbox
              value={Columns.BasisPoints}
              checked={store.toggled(Columns.BasisPoints)}
              onChange={(e: any) => {
                store.toggleColumn(Columns.BasisPoints);
              }}
            >
              {Columns.BasisPoints}
            </Checkbox>
          </Box>

          <Box padding="sm none">
            <Checkbox
              value={Columns.PerMillion}
              checked={store.toggled(Columns.PerMillion)}
              onChange={(e: any) => {
                store.toggleColumn(Columns.PerMillion);
              }}
            >
              {Columns.PerMillion}
            </Checkbox>
          </Box>

          <Box padding="sm none">
            <Checkbox
              value={Columns.PerProject}
              checked={store.toggled(Columns.PerProject)}
              onChange={(e: any) => {
                store.toggleColumn(Columns.PerProject);
              }}
            >
              {Columns.PerProject}
            </Checkbox>
          </Box>

          <Box padding="sm none">
            <Checkbox
              value={Columns.PerEmployee}
              checked={store.toggled(Columns.PerEmployee)}
              onChange={(e: any) => {
                store.toggleColumn(Columns.PerEmployee);
              }}
            >
              {Columns.PerEmployee}
            </Checkbox>
          </Box>
          <Box padding="sm none">
            <Font weight="bold">Subtotals</Font>
            <Box padding="sm none">
              <Checkbox
                value={"prof_services"}
                checked={store.subtotals.prof_services}
                onChange={(e: any) => {
                  store.toggleProfServices("prof_services");
                }}
              >
                One Time Fees
              </Checkbox>
            </Box>
          </Box>
          <Box padding="sm none">
            <Font weight="bold">Disclaimers</Font>
            <Box padding="sm none">
              <Checkbox
                value={"taxes"}
                checked={store.disclaimers.taxes}
                onChange={(e: any) => {
                  store.toggleDisclaimer("taxes");
                }}
              >
                Excludes Tax
              </Checkbox>
            </Box>
            <Box padding="sm none">
              <Checkbox
                  value={"descriptions"}
                  checked={store.descriptions.enabled}
                  onChange={(e: any) => {
                    store.toggleDescriptions("enabled");
                  }}
              >
                Descriptions
              </Checkbox>
            </Box>
          </Box>

          <Box padding="sm none">
            <Font weight="bold"> PDF Export </Font>
            <Box padding="sm none">
              <Checkbox
                value={"page_break"}
                checked={store.pdfoptions.page_break}
                onChange={(e: any) => {
                  store.togglePDF("page_break");
                }}
              >
                Quote Page Break
              </Checkbox>
            </Box>
          </Box>
        </Box>
      </Popover.Content>
    }
  >
    {() => (
      <Button variant="dropdown" dropdown>
        Options
      </Button>
    )}
  </Popover>
));

const frequencyDescription = (frequency: number) => {
  switch (frequency) {
    case 2:
      return <span className="text--description">Semi-Annually</span>;
    case 4:
      return <span className="text--description">Per Quarter</span>;
    case 12:
      return <span className="text--description">Per Month</span>;
    default:
      return null;
  }
};
