import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table"];

  addRow(event) {
    event.preventDefault();

    const tr = this.tableTarget.insertRow(-1);
    tr.setAttribute("data-bp-form-from", "0");
    tr.setAttribute("data-bp-form-to", "0");
    tr.setAttribute("data-bp-form-currency", this.data.get("currency"));
    tr.setAttribute("data-controller", "bp-form");
    tr.className = "financial-row--error";
    tr.innerHTML = `
      <td>
        <input
          type="text"
          name="pricing[data][basis_points][][from]"
          value="0"
          class="financial-input"
          data-target="bp-form.fromInput"
          data-action="keyup->bp-form#updateFrom"
        >
      </td>
      <td>
        <input
          type="text"
          name="pricing[data][basis_points][][to]"
          value="0"
          class="financial-input"
          data-target="bp-form.toInput"
          data-action="keyup->bp-form#updateTo"
        >
      </td>
      <td class="separated">
        <input
          type="text"
          name="pricing[data][basis_points][][value]"
          value="0.0"
          class="financial-input"
          data-target="bp-form.source"
          data-action="keyup->bp-form#calculate"
        >
      </td>
      <td data-target="bp-form.from">-</td>
      <td data-target="bp-form.to">-</td>
      <td><button data-action="click->basis-point#removeRow">x</button></td>
      <td>
        <div class="financial-row__message" data-target="bp-form.message">
          Price is empty
        </div>
      </td>

    `;
  }

  removeRow(event) {
    event.preventDefault();

    if (confirm("Remove this row?")) {
      this.tableTarget.deleteRow(event.target.closest("tr").rowIndex);
    }
  }
}
