import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "requiresForm", "atLeastOneForm", "cannotPairWithForm", "cannotSellBelowForm", "cannotSellAboveForm", "cannotSellAboveAcvForm" ]

  initialize() {
    const active = this.data.get("active");

    switch (active) {
      case "requires":
        this.requiresFormTarget.classList.remove("hidden");
        break;
      case "at_least_one":
        this.atLeastOneFormTarget.classList.remove("hidden");
        break;
      case "cannot_pair_with":
        this.cannotPairWithFormTarget.classList.remove("hidden");
        break;
      case "cannot_sell_below":
        this.cannotSellBelowFormTarget.classList.remove("hidden");
        break;
      case "cannot_sell_above":
        this.cannotSellAboveFormTarget.classList.remove("hidden");
        break;
      case "cannot_sell_above_acv":
        this.cannotSellAboveAcvFormTarget.classList.remove("hidden");
        break;
    }
  }

  switch_requires() {
    this.requiresFormTarget.classList.remove("hidden");
    this.atLeastOneFormTarget.classList.add("hidden");
    this.cannotPairWithFormTarget.classList.add("hidden");
    this.cannotSellBelowFormTarget.classList.add("hidden");
    this.cannotSellAboveFormTarget.classList.add("hidden");
    this.cannotSellAboveAcvFormTarget.classList.add("hidden");
  }

  switch_at_least_one() {
    this.requiresFormTarget.classList.add("hidden");
    this.atLeastOneFormTarget.classList.remove("hidden");
    this.cannotPairWithFormTarget.classList.add("hidden");
    this.cannotSellBelowFormTarget.classList.add("hidden");
    this.cannotSellAboveFormTarget.classList.add("hidden");
    this.cannotSellAboveAcvFormTarget.classList.add("hidden");
  }

  switch_cannot_pair_with() {
    this.cannotPairWithFormTarget.classList.remove("hidden");
    this.atLeastOneFormTarget.classList.add("hidden");
    this.requiresFormTarget.classList.add("hidden");
    this.cannotSellBelowFormTarget.classList.add("hidden");
    this.cannotSellAboveFormTarget.classList.add("hidden");
    this.cannotSellAboveAcvFormTarget.classList.add("hidden");
  }

  switch_cannot_sell_below() {
    this.cannotSellBelowFormTarget.classList.remove("hidden");
    this.cannotSellAboveFormTarget.classList.add("hidden");
    this.cannotSellAboveAcvFormTarget.classList.add("hidden");
    this.atLeastOneFormTarget.classList.add("hidden");
    this.requiresFormTarget.classList.add("hidden");
    this.cannotPairWithFormTarget.classList.add("hidden");
  }

  switch_cannot_sell_above() {
    this.cannotSellAboveFormTarget.classList.remove("hidden");
    this.cannotSellAboveAcvFormTarget.classList.add("hidden");
    this.atLeastOneFormTarget.classList.add("hidden");
    this.requiresFormTarget.classList.add("hidden");
    this.cannotPairWithFormTarget.classList.add("hidden");
    this.cannotSellBelowFormTarget.classList.add("hidden");
  }

  switch_cannot_sell_above_acv() {
    this.cannotSellAboveAcvFormTarget.classList.remove("hidden");
    this.cannotSellBelowFormTarget.classList.add("hidden");
    this.cannotSellAboveFormTarget.classList.add("hidden");
    this.atLeastOneFormTarget.classList.add("hidden");
    this.requiresFormTarget.classList.add("hidden");
    this.cannotPairWithFormTarget.classList.add("hidden");
  }
}
