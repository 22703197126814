import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fromInput", "from", "toInput", "to", "message", "value", "source" ]

  initialize() {
    this.calculate();
  }

  calculate() {
    this.clearErrors();

    const value = this.sourceTarget.value;

    if (value === "") {
      this.error("Price is empty");

      return;
    }

    if (this.sourceTarget.value.startsWith(this.data.get("currency")) || this.sourceTarget.value.startsWith("$")) {
      const price = Number(this.sourceTarget.value.slice(1));

      if (isNaN(price)) {
        this.error("Invalid entry... make sure not to use commas");
        return
      }

      this.update(price, price);

      return;
    }

    const bp = Number(this.sourceTarget.value / 100);

    if (isNaN(bp)) {
      this.error("Price is not a percentage or dollar value");
      this.update("-", "-");

      return
    }

    if (bp >= .01) {
      this.warn("Percentage larger than 100... are you sure this is correct?");
      return
    }

    if (bp === 0) {
      this.warn("Price set to 0... are you sure this is correct?");
      return
    }

    this.update(Math.ceil(bp * this.fromPrice), Math.ceil(bp * this.toPrice));
  }

  clearErrors() {
    this.element.classList.remove("financial-row--error");
    this.element.classList.remove("financial-row--warning");
  }

  error(message) {
    this.element.classList.add("financial-row--error");
    this.messageTarget.innerHTML = message;
  }

  warn(message) {
    this.element.classList.add("financial-row--warning");
    this.messageTarget.innerHTML = message;
  }

  update(low, high) {
    this.fromTarget.innerHTML = `${this.currencySymbol}${low.toLocaleString()}`
    this.toTarget.innerHTML = `${this.currencySymbol}${high.toLocaleString()}`
  }

  updateFrom() {
    this.fromPrice = this.fromInputTarget.value;
  }

  updateTo() {
    this.toPrice = this.toInputTarget.value;
  }

  get currencySymbol() {
    return this.data.get("currency");
  }


  get fromPrice() {
    return parseInt(this.data.get("from").replace(/,/g, ''))
  }

  set fromPrice(value) {
    this.data.set("from", value);
    this.calculate();
  }

  get toPrice() {
    return parseInt(this.data.get("to").replace(/,/g, ''))
  }

  set toPrice(value) {
    this.data.set("to", value);
    this.calculate();
  }
}
