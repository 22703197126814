import { action, computed, observable } from "mobx";
import { Calculation } from "../../reps/quote/api";

export enum Columns {
  Quantities = "Quantity",
  BasisPoints = "Basis Points",
  PerEmployee = "Per Employee",
  PerMillion = "Per Million",
  PerProject = "Per Project",
}

export interface Option {
  name: string;
  hidden: boolean;
  calculation: Calculation;
}

interface Disclaimers {
  taxes: boolean;
}

interface Descriptions {
  enabled: boolean;
}

interface Subtotals {
  prof_services: boolean;
}

interface Pdfoptions{
  page_break: boolean;
}

interface Props {
  options: Option[];
  edit_path?: string;
  sell_multiple: boolean;
}

export default class RootStore {
  readonly options = observable<Option>([]);
  readonly columns = observable<Columns>([]);

  @observable disclaimers: Disclaimers = { taxes: false }
  @observable descriptions: Descriptions = { enabled: false }
  @observable subtotals: Subtotals = { prof_services: false }
  @observable pdfoptions: Pdfoptions = { page_break: false }

  @observable edit_path: string;
  @observable sell_multiple: boolean;

  constructor(props: Props) {
    this.options.replace(props.options);
    this.edit_path = props.edit_path || "";
    this.sell_multiple = props.sell_multiple || false;
    if(this.sell_multiple){
      this.columns.push(Columns.Quantities);
    }
    this.columns.push(Columns.PerEmployee);
    this.columns.push(Columns.PerMillion);
    this.columns.push(Columns.PerProject);
  }

  @computed
  get visible() {
    return this.options.filter(option => !option.hidden)
  }

  @action
  toggleColumn = (column: Columns) => {
    if (this.toggled(column)) {
      this.columns.remove(column);
    } else {
      this.columns.push(column);
    }
  };

  @action
  refresh = (options: Option[]) => {
    this.options.replace(options);
  }

  toggled = (column: Columns) => {
    return !!this.columns.find(existing => existing == column);
  };

  @action
  toggleDisclaimer = (disclaimer: keyof Disclaimers) => {
    this.disclaimers[disclaimer] = !this.disclaimers[disclaimer];
  }

  @action
  toggleDescriptions = (descriptions: keyof Descriptions) => {
    this.descriptions[descriptions] = !this.descriptions[descriptions];
  }

  @action
  toggleProfServices = (s: keyof Subtotals) => {
    this.subtotals[s] = !this.subtotals[s];
  }

  @action
  togglePDF = (setting: keyof Pdfoptions) => {
    this.pdfoptions[setting] = !this.pdfoptions[setting];
  }
}
