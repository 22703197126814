import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "total" ]

  initialize() {
    this.change()
  }

  change() {
    const total = this.inputTargets.reduce((total, input) => {
      return total + parseInt(input.value)
    }, 0);

    this.totalTarget.textContent =
      `${this.data.get('currency-symbol')}${total.toLocaleString()}`
  }
}
