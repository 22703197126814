import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "status" ]

  initialize() {
    this.showingNotifcation = false
  }


  modified() {
	  event.target.parentElement.classList.add("form-group--modified");

		this.statusTarget.innerHTML = `
			<div class="banner">
				Data on page has been altered, data displayed does not reflect selected
				options.
			</div>
		`
	}

	onDiscountChange() {
    this._notify();
  }

  onQuoteChange() {
    this._notify();
  }

  _notify() {
    if (!this.showingNotifcation) {
      this.showingNotifcation = true;

      this.statusTarget.innerHTML = `
        <div class="banner">
          Data on page has been altered, data displayed does not reflect selected
          options.
        </div>
      `
    }
  }
}
