import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table"];

  addRow(event) {
    event.preventDefault();
    const tr = this.tableTarget.insertRow(-1);
    tr.innerHTML = `
      <td>
        <input
          type="text"
          name="pricing[data][full_time_employees][][from]"
          value="0"
          class="financial-input"
        >
      </td>
      <td>
        <input
          type="text"
          name="pricing[data][full_time_employees][][to]"
          value="0"
          class="financial-input"
        >
      </td>
      <td class="separated">
        <input
          type="text"
          name="pricing[data][full_time_employees][][value]"
          value="0"
          class="financial-input"
        >
      </td>
      <td><button data-action="click->fte-from#removeRow">x</button></td>
    `;
  }

  removeRow(event) {
    event.preventDefault();
    console.log("remove a row");
  }
}
